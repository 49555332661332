import React from 'react'
import SeoPage from '../components/SeoPage'
import UpperForm from '../components/UpperForm'
import BottomForm from '../components/BottomForm'
import { StaticImage } from 'gatsby-plugin-image'
import LeadImage from '../images/avocat-bail-commercial-quebec-1024x683.jpg'
import { graphql, Link } from 'gatsby'

const Test = ({ data, location, ...props }) => (
    <SeoPage
        location={location}
        title="Trouvez un avocat pour rédiger un bail commercial! Bail brut vs. Bail net! - Soumissions Avocat"
        description="Trouvez un avocat SPÉCIALISÉ en matière de bail commercial avec l’aide de Soumissions Avocat!"
        image={LeadImage}
    >
        <h1>
            Trouvez un avocat pour rédiger un bail commercial! Bail brut vs.
            Bail net!
        </h1>

        <p>
            <strong>
                Pour bien des entreprises québécoises, la location d’un local
                commercial fait partie intégrante de leurs activités d’affaires.{' '}
            </strong>
        </p>
        <p>
            Rares sont les PME et même les grandes entreprises qui ont les
            moyens financiers d’acheter leur propre centre d’affaires, et c’est
            là que la location d’un espace commercial entre en jeu! Vous l’aurez
            deviné, qui dit location d’espace dit également signature d’un bail!
        </p>

        <StaticImage
            alt="avocat bail commercial quebec"
            src="../images/avocat-bail-commercial-quebec-1024x683.jpg"
            placeholder="blurred"
            imgStyle={{ maxWidth: '80%', margin: '0 auto' }}
        ></StaticImage>

        <p>
            On pourrait être porté à croire que le bail commercial ressemble de
            près ou de loin au bail résidentiel, mais sachez que la réalité est
            toute autre! Même s’ils portent un nom similaire, les ressemblances
            s’arrêtent là, car le bail commercial est une entente beaucoup moins
            encadrée et protectrice que celle dans le domaine résidentiel.
        </p>
        <p>
            <strong>
                Soumissions Avocat vous explique TOUT ce que vous devez savoir
                pour conclure un bail commercial avantageux pour votre
                entreprise – En prime, on vous déniche l’avocat qu’il vous faut
                pour négocier, rédiger et signer le bail!
            </strong>
        </p>

        <h2>
            Pourquoi avez-vous besoin d’un avocat pour rédiger un bail
            commercial?{' '}
        </h2>

        <p>
            Il est tout à fait possible de signer un bail commercial au bas de
            la page sans rien négocier, et le tour est joué!
        </p>
        <p>
            Ceci dit, une telle démarche est loin d’être optimale et vous privez
            votre entreprise de plusieurs bénéfices. En plus, vos droits
            pourront facilement être bafoués si vous signez un bail sans obtenir
            de conseils juridiques au préalable!
        </p>

        <p>
            <strong>
                Lors de la conclusion d’un bail commercial, les services d’un
                avocat sont donc essentiels pour!
            </strong>
        </p>

        <ul>
            <li>Négocier le bail commercial</li>
            <li>Rédiger le bail et les clauses</li>
            <li>Représenter votre entreprise en cas de litige</li>
            <li>L’avocat médiateur, essentiel en cas de problème de bail!</li>
        </ul>

        <p>
            <strong>
                Un bail commercial peut durer 5 ans, 10 ans ou même 100 ans!{' '}
            </strong>
            On est donc loin des baux résidentiels qui prennent fin au bout d’un
            an, et c’est donc dire que vous pouvez être coincé dans une entente
            désavantageuse très longtemps si vous êtes pressé de signer.
        </p>
        <p>
            <strong>Ne faites pas cette erreur!</strong> Les baux commerciaux
            sont plus complexes qu’on ne le pense, alors apprenez juste ici tout
            ce qu’il y a à savoir à leur sujet et demandez l’aide d’un avocat
            sans tarder.
        </p>

        <a href="https://soumissionsavocat.ca/comparer-avocats/">
            COMPAREZ LES AVOCATS POUR VOTRE BAIL COMMERCIAL
        </a>

        <h2>Qu’est-ce qu’un bail commercial complet devrait contenir? </h2>

        <StaticImage
            alt="bail commercial brut net"
            src="../images/bail-commercia-brut-net-scaled.jpg"
            placeholder="blurred"
            imgStyle={{ maxWidth: '80%', margin: '0 auto' }}
        ></StaticImage>
        <h2>Stipulez des clauses claires!</h2>
        <p>
            Le bail commercial est une entente qui prévoit et protège les droits
            du locataire comme du locateur. Il s’agit d’une entente à deux sens,
            et le contenu du contrat de bail devrait donc refléter cette réalité
            dans le but de prévenir les litiges et d’assurer une location
            paisible du début à la fin!{' '}
        </p>

        <p>
            <strong>
                Voici les clauses essentielles que votre avocat devra inclure
                dans le bail!{' '}
            </strong>
        </p>

        <ul>
            <li>La description des lieux loués (avec précision)</li>
            <li>
                Les frais à la charge du locataire (réparations, assurances,
                taxes, etc.)
            </li>
            <li>Les modalités de renouvellement</li>
            <li>Les clauses d’assurances</li>
            <li>Les droits d’usage du locataire</li>
            <li>Les droits du locateur (visites, accès, travaux, etc.)</li>
        </ul>

        <p>
            <strong>
                Plus l’entente est complète et détaillée, moins il y a de zones
                grises et moins il y a de frictions.{' '}
            </strong>
            Quand les droits des parties sont clairs, les possibilités de
            désaccord sont moins élevées, ce qui avantage autant le locataire
            que le locateur commercial! 
        </p>

        <h2>
            Les obligations du locataire et du locateur commercial en cours de
            bail!{' '}
        </h2>

        <p>
            N’importe quel contrat vient avec des droits et des obligations,
            incluant le bail commercial. L’avantage du bail commercial,
            contrairement au bail résidentiel, c’est que ces droits et
            obligations peuvent être librement négociés entre les parties.
        </p>
        <p>
            <strong>
                Mais lorsque les parties ne prévoient rien, la loi stipule des
                obligations de base!{' '}
            </strong>
        </p>

        <p>
            <strong>
                Avant le début de la location, le locateur a l’obligation de
                livrer en bon état!{' '}
            </strong>
        </p>
        <p>
            Ce « bon état » implique que le local loué doit pouvoir servir à la
            destination convenue entre le locataire et le locateur à la
            signature du bail. Ce devoir imposé au locateur fait partie de son
            obligation de délivrance! Si le local loué empêche la jouissance
            paisible des lieux, le propriétaire est tenu responsable des vices,
            et les réparations sont à sa charge.
        </p>

        <p>
            <strong>
                Le locataire est tenu d’effectuer les réparations d’entretien,
                et parfois plus!{' '}
            </strong>
        </p>
        <p>
            Les règles générales du louage imposent au locataire la
            responsabilité d’effectuer les réparations d’entretien, également
            appelées les « menues réparations ». Encore une fois, le degré de
            responsabilité du locataire quant aux frais peut varier selon le
            type de bail commercial (brut, net, net net, etc.).{' '}
        </p>
        <p>
            <strong>
                Pour ce qui est des améliorations, cet aspect doit être négocié
                entre les parties!{' '}
            </strong>
            Notez qu’en matière commerciale, le locataire et le locateur ont
            toujours la chance de s’entendre entre eux sur le partage des
            responsabilités et des frais de location engendrés!
        </p>
        <p>
            Par exemple, un locataire peut consentir à prendre le local{' '}
            <strong>dans l’état où il se trouve</strong>, auquel cas le locateur
            sera libéré de son obligation de délivrance, même si les lieux ne
            sont pas en parfait état.
        </p>
        <p>
            Et dans tous les types de baux, le locateur doit permettre au
            locataire la jouissance paisible des lieux! Cela implique de
            permettre au locataire d’utiliser le local aux fins convenues, sans
            interruption ou nuisance de la part du propriétaire.
        </p>

        <h2>Quels recours contre un locateur ou un locataire en défaut? </h2>

        <StaticImage
            alt=""
            src="../images/avocat-redaction-negociation-bail-scaled.jpg"
            placeholder="blurred"
            imgStyle={{ maxWidth: '80%', margin: '0 auto' }}
        ></StaticImage>
        <h2>L'avocat est là pour faire respecter vos droits!</h2>
        <p>
            La location d’un local n’est pas nécessairement de tout repos et il
            est possible que des désaccords surviennent entre locataires et
            locateurs. Lorsque c’est le cas, il faut rapidement envisager les
            différents recours afin de faire respecter les droits de votre
            entreprise (que vous soyez locataire ou locateur, d’ailleurs!).
        </p>

        <p>
            <strong>
                Voici les principaux recours prévus par la loi pour faire
                respecter vos droits!
            </strong>
        </p>

        <ul>
            <li>
                L’exécution en nature (Faire respecter les conditions du bail,
                telles que rédigées)
            </li>
            <li>
                La diminution de loyer (Pour compenser l’inexécution ou
                l’exécution incomplète)
            </li>
            <li>
                Les dommages-intérêts (Lorsque l’inexécution vous a causé un
                préjudice)
            </li>
            <li>La résiliation du bail (En cas de motif sérieux)</li>
        </ul>

        <p>
            <strong>
                Quel recours est le plus optimal à entreprendre pour faire
                respecter vos droits?{' '}
            </strong>
        </p>
        <p>
            Tout dépend de la gravité de la violation des conditions prévues au
            bail. Une enfreinte minime aux conditions de la part du locateur
            pourra justifier une diminution de loyer, alors qu’une non-exécution
            majeure pourra nécessiter une résiliation complète.
        </p>

        <a href="https://soumissionsavocat.ca/comparer-avocats/">
            TROUVEZ UN AVOCAT - FAITES RESPECTER VOS DROITS
        </a>

        <h2>
            Les différences entre un bail commercial et un bail résidentiel!{' '}
        </h2>

        <p>
            <strong>
                Le bail résidentiel comprend des règles protectrices en faveur
                du locataire!{' '}
            </strong>
            Pour des raisons sociales évidentes et dans le but de prévenir les
            abus, le bail résidentiel a été conçu de manière favorable aux
            locataires, ce qui n’est aucunement le cas du bail commercial! Dans
            le monde des affaires, le contrat de bail est la loi des parties!
        </p>
        <p>
            <strong>
                Voici quelques exemples de différences entre le bail commercial
                et résidentiel!{' '}
            </strong>
        </p>

        <p>
            <strong>
                Le locataire (votre entreprise) n’a pas un droit automatique au
                renouvellement!{' '}
            </strong>
        </p>
        <p>
            Dans le cadre d’un bail résidentiel, le locataire a un droit
            fondamental au maintien dans les lieux, ce qui signifie que le
            locateur doit renouveler son bail, à moins d’effectuer une reprise
            dans les limites permises par la loi.
        </p>
        <p>
            En matière commerciale, la fin du contrat signifie la fin de la
            location, à moins que les parties ne s’entendent sur les modalités
            d’un nouveau bail (ou qu’ils aient prévu une clause de
            renouvellement).{' '}
        </p>
        <p>
            <strong>
                Un locateur commercial est également en droit d’exiger un dépôt.{' '}
            </strong>
        </p>
        <p>
            Également appelé un « dépôt de garantie », ce montant d’argent sert
            à couvrir les frais en cas de bris ou de non-respect des conditions
            de location. Pratique courante en matière commerciale, sachez que
            l’exigence d’un tel dépôt est formellement interdite dans le milieu
            des baux résidentiels. {' '}
        </p>
        <p>
            <strong>
                Un propriétaire commercial peut restreindre/interdire la
                sous-location!{' '}
            </strong>
        </p>
        <p>
            Dans le secteur résidentiel, un propriétaire ne peut s’opposer à la
            cession/sous-location que pour un motif sérieux. Dans le secteur
            commercial, comme les autres locataires ou encore le propriétaire
            lui-même peuvent voir ses intérêts affectés par l’arrivée d’un
            nouveau locataire, le bail commercial peut valablement interdire la
            sous-location. <strong></strong>
        </p>
        <p>
            <strong>
                Il est aussi possible de prévoir des clauses de non-concurrence
                dans le bail commercial!{' '}
            </strong>
        </p>
        <p>
            Cette clause est surtout employée dans les espaces commerciaux
            comprenant plusieurs locataires, comme un centre commercial, par
            exemple. La clause de non-concurrence interdit donc au propriétaire
            de louer à un locataire exploitant un commerce similaire au vôtre
            dans le même établissement. Une telle disposition peut s’avérer plus
            que nécessaire selon votre domaine d’activité!{' '}
            <strong>
                En ce qui concerne le bail commercial, le locataire et le
                locateur (propriétaire) ont beaucoup plus de latitude!{' '}
            </strong>
            Et c’est exactement pour cette raison que vous avez besoin d’un
            avocat spécialisé pour rédiger et négocier votre bail commercial!
        </p>

        <h2>
            Bail brut, bail net, bail net net et bail hypernet : les différences
            à connaître!
        </h2>

        <p>
            Le domaine du louage commercial emploi des termes spécifiques pour
            qualifier les différents baux commerciaux, comme le bail brut, le
            bail net, le bail net net ainsi que le bail net net net.
        </p>
        <p>
            <strong>
                Pour vous expliquer le tout simplement, plus un bail est net,
                plus le locataire assume une part importante de frais de
                location!
            </strong>
        </p>

        <p>
            <strong>Bail brut </strong>
        </p>
        <p>
            Lors de la signature d’un bail brut, le locataire commercial
            n’assume que le loyer convenu en guise de frais de location, et rien
            de plus. Il s’agit de la forme de bail commercial la plus simple.{' '}
        </p>

        <p>
            <strong>Bail net </strong>
        </p>
        <p>
            Un bail net implique le paiement du loyer convenu entre le locataire
            et le locateur, auquel on ajoute l’obligation pour le locataire de
            payer les taxes municipales et scolaires. Un bail net peut également
            inclure des frais négociés entre les locataires et le propriétaire.
        </p>

        <p>
            <strong>Bail net net (Supernet)</strong>
        </p>
        <p>
            Un bail sera qualité de « supernet » lorsqu’il impose les mêmes
            obligations que le bail net, tout en imposant au locataire
            l’obligation de payer les réparations et rénovations importantes.
        </p>

        <p>
            <strong>
                Un bail commercial peut également être « net net net »
                (Hypernet), mais faites bien attention avant de signer!{' '}
            </strong>
            Le choix entre un bail brut, net, net net ou hypernet peut impliquer
            davantage de dépenses pour votre entreprise, réduisant vos marges de
            profit. Assurez-vous d’être dument conseillé avant de faire un
            choix!
        </p>

        <h2>Publication du bail commercial : une étape essentielle! </h2>

        <p>
            <strong>
                En quoi consiste la publication d’un bail commercial?{' '}
            </strong>
        </p>
        <p>
            Cette publication consiste à enregistrer le bail au Registre foncier
            afin de le rendre opposable aux tiers, et surtout aux futurs
            acquéreurs de l’immeuble si jamais le propriétaire décidait de
            vendre!
        </p>
        <p>
            <strong>
                Pourquoi est-il INDISPENSABLE de publier un bail commercial pour
                une entreprise locataire?{' '}
            </strong>
        </p>
        <p>
            Parce que si le bail est publié, le nouveau propriétaire est au
            courant de votre statut de locataire et devra le respecter jusqu’à
            son échéance. Votre place est donc sécurisée quoiqu’il arrive en
            publiant votre bail!
        </p>
        <p>
            <strong>
                Qu’arrive-t-il si le propriétaire vend l’immeuble et que votre
                bail n’est pas publié?{' '}
            </strong>
        </p>
        <p>
            Le nouveau propriétaire ne pourra pas y mettre fin s’il reste 12
            mois et moins au bail. Toutefois, si le bail n’est pas publié et
            qu’il reste encore plus de 12 mois à écouler à l’entente, celui-ci
            sera en droit d’exiger la résiliation du contrat de louage, à
            condition d’envoyer le préavis de 6 mois prévu par la loi.
        </p>
        <p>
            <strong>
                Si le bail est à durée indéterminée, le nouveau propriétaire est
                en droit de mettre fin au bail même s’il a été publié au
                Registre foncier!{' '}
            </strong>
        </p>

        <h2>Comment fonctionne le renouvellement d’un bail commercial? </h2>

        <p>
            <strong>
                Rappelez-vous que le bail commercial a très peu de points en
                commun avec le bail résidentiel!{' '}
            </strong>
        </p>
        <p>
            Dans le domaine commercial, le bail est un contrat librement négocié
            et qui prend fin à la date convenue. En l’absence d’une clause de
            renouvellement, le propriétaire peut mettre le locataire dehors à la
            fin du bail!
        </p>
        <p>
            <strong>
                Mais qu’arrive-t-il si le locataire reste dans l’immeuble malgré
                l’expiration du bail?{' '}
            </strong>
        </p>
        <p>
            Advenant que <strong>rien ne soit prévu au bail</strong>, si le
            locataire continu à occuper le local pendant 10 jours après la fin
            du bail et que le propriétaire n’intervient pas, le bail est
            automatiquement reconduit. Si le bail était d’une durée d’un an et
            plus, il est renouvelé pour une période d’un an, et si le bai; était
            d’une durée initiale de moins d’un an, il est renouvelé pour cette
            même durée initiale.
        </p>

        <a href="https://soumissionsavocat.ca/comparer-avocats/">
            RENOUVELLEZ VOTRE BAIL AVEC UN AVOCAT - CLIQUEZ ICI
        </a>

        <h2>
            Questions fréquentes sur les droits du locataire d’un bail
            commercial!{' '}
        </h2>

        <p>
            <strong>
                Un bail commercial doit-il obligatoirement être écrit? Un bail
                verbal est-il valide?  
            </strong>
        </p>
        <p>
            Rien dans la loi n’oblige les parties à un bail commercial de
            conclure un bail commercial par écrit. Cependant, il s’avère
            extrêmement risqué de conclure un bail verbal, car vous n’avez
            aucune preuve de l’existence de votre entente, et encore moins des
            clauses convenues. Se fier à une telle entente à l’amiable est une
            très mauvaise idée; assurez-vous de mettre votre bail par écrit!{' '}
        </p>
        <p>
            <strong>
                Un propriétaire peut-il expulser un locataire avant la fin du
                bail?{' '}
            </strong>
        </p>
        <p>
            Pas sans raison! « Un contrat est un contrat », comme le dit le
            proverbe, et celui-ci s’applique aux baux commerciaux! Toutefois,
            comme pour n’importe quel contrat, la violation des obligations de
            la part du locataire ou du locateur pourra justifier la résiliation
            du bail. Pour résilier complètement l’entente, il faudra toutefois
            invoquer un motif sérieux!{' '}
        </p>
        <p>
            <strong>
                En cas de conflit entre propriétaires/locataire commercial,
                pouvez-vous aller à la Régie du logement pour régler le
                problème?{' '}
            </strong>
        </p>
        <p>
            Non, la Régie du logement (maintenant appelée le Tribunal
            administratif du logement) est un tribunal compétent uniquement pour
            entendre les disputes entre locataires et locateurs dans le{' '}
            <strong>secteur résidentiel</strong>. En cas de litige, c’est vers
            la Cour du Québec ou la Cour supérieure que vous devrez vous tourner
            pour obtenir un règlement.
        </p>
        <p>
            <strong>
                Comme les baux commerciaux sont très personnalisés (et
                personnalisables
            </strong>
            ), la meilleure solution est de consulter un avocat d’expérience et
            qui a rédigé des baux commerciaux maintes et maintes fois!
        </p>
        <p>
            <strong>
                Soumissions Avocat a un réseau de partenaires rempli de tels
                experts, vous n’avez qu’à nous contacter pour trouver celui
                qu’il vous faut!
            </strong>
        </p>

        <h2>
            Soumissions Avocat est LA référence qu’il vous faut pour votre bail
            commercial!{' '}
        </h2>

        <p>
            Vous l’avez vu, le bail commercial est une entente qui prendre mille
            et une formes afin de s’adapter à la réalité et aux besoins de votre
            entreprise. Comme le bail peut être rédigé de plusieurs façons,
            assurez-vous de négocier en compagnie d’un avocat en droit des
            affaires spécialisé en rédaction de baux commerciaux!
        </p>
        <p>
            <strong>
                Cet allié du monde juridique vous aidera à signer une entente
                qui maximise le profit de votre entreprise et qui protège ses
                intérêts à long terme. Sachant cela, qu’attendez-vous pour
                contacter un avocat chargé de négocier votre bail commercial!
            </strong>
        </p>
        <p>
            <strong>
                Remplissez le formulaire en bas de page et nous assurons une
                mise en contact gratuite et sans engagement avec un avocat
                spécialisé en baux commerciaux!
            </strong>
        </p>
    </SeoPage>
)

export default Test
